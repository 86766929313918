<template>
  <div>
    <div
      class="
        part
        border-radius-6
        height-calc-type1
        box-shadow-light-grey
        padding-20
      "
    >
      <div class="flex-between operation padding-bottom-20">
        <div>
          <el-button @click="getList">刷新数据</el-button>
          <el-button
            class="custom-button-margin-left"
            type="success"
            @click="isCopy = true"
            :disabled="selected.length === 0"
            >批量复制消息
          </el-button>
          <el-button
            class="custom-button-margin-left"
            type="warning"
            @click="isUpdate = true"
            :disabled="selected.length === 0"
            >批量修改状态
          </el-button>
          <el-button
            class="custom-button-margin-left"
            type="danger"
            @click="handleDelete(selected)"
            :disabled="selected.length === 0"
            >批量删除
          </el-button>
        </div>
        <div>
          <el-input
            class="margin-right-twentyFour"
            placeholder="备注内容"
            v-model="search"
            style="width: 220px"
            @keydown.enter.native="getList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
          <el-button
            type="primary"
            @click="handleDialogShow(null, 'isEdit')"
            icon="el-icon-plus"
            >新增延迟回复</el-button
          >
        </div>
      </div>
      <el-table
        v-loading="loading"
        :data="messageList"
        height="calc(100vh - 278px)"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" width="80" label="ID"> </el-table-column>
        <el-table-column prop="msgType" label="消息类型" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.msgType === 'text'" type="info"
              >文本</el-tag
            >
            <el-tag v-else-if="scope.row.msgType === 'news'">图文</el-tag>
            <el-tag v-else-if="scope.row.msgType === 'voice'">音频</el-tag>
            <el-tag v-else-if="scope.row.msgType === 'image'">图片</el-tag>
            <el-tag v-else-if="scope.row.msgType === 'miniprogrampage'"
              >小程序卡片</el-tag
            >
            <el-tag v-else type="danger">未知</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark" min-width="150" label="备注">
        </el-table-column>
        <el-table-column
          prop="sendOffset"
          label="定时发送偏移时间"
          min-width="100"
        >
          <template slot-scope="scope">
            {{ formatOffset(scope.row.sendOffset) }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="是否开启" min-width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" min-width="100">
        </el-table-column>
        <el-table-column prop="action" width="220" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="详情"
              placement="top-start"
            >
              <el-button
                type="info"
                icon="el-icon-document"
                circle
                @click="handleDialogShow(scope.row, 'showDetail')"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top-start"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="handleDialogShow(scope.row, 'isEdit')"
              ></el-button>
            </el-tooltip>
            <el-dropdown
              size="medium"
              style="vertical-align: middle; margin-left: 10px"
              trigger="click"
              @command="(command) => handleRowClick(command, scope.row)"
            >
              <el-button class="button-small">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <!--              <el-dropdown-item command="copy">复制消息</el-dropdown-item>-->
                <el-dropdown-item style="color: #f56c6c" command="delete"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <edit
      :show="isEdit"
      :account="channelList"
      :info="current"
      :moduleType="2"
      :accountId="accountChannelId"
      @close="isEdit = false"
      @refresh="getList"
    />
    <!-- <el-drawer
      title="延迟回复详情"
      direction="rtl"
      size="650px"
      :visible.sync="showDetail"
    > -->
    <detail
      :show="showDetail"
      :info="current"
      @close="showDetail = false"
      :moduleType="2"
    />
    <!-- </el-drawer> -->
    <el-dialog
      title="复制消息"
      :visible.sync="isCopy"
      center
      append-to-body
      width="550px"
    >
      <copy
        :show="isCopy"
        :accountList="targetList"
        :info="selected"
        @close="isCopy = false"
        @refresh="getList"
      />
    </el-dialog>
    <el-dialog
      title="修改开启状态"
      :visible.sync="isUpdate"
      width="500px"
      :close-on-click-modal="false"
      :before-close="() => (this.isUpdate = false)"
    >
      <el-form>
        <el-form-item label="是否开启">
          <el-switch
            v-model="status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
          {{ status === 0 ? "否" : "是" }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isUpdate = false">取 消</el-button>
        <el-button @click="handleUpdateSubmit" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { messagePlatformList } from "@/assets/js/options"; //客服消息支持平台
import {
  getDelayMessage,
  deleteDelayMessage,
  changeDelayStatus,
} from "../../../api/service";
// import Edit from './edit'
import Edit from "../../service/edit";
// import Detail from './detail'
import Detail from "./../../service/detail";
import Copy from "./copy";

export default {
  name: "Delay",
  props: {
    accountChannelId: Number,
  },
  data() {
    return {
      loading: false,
      messageList: [],
      current: null,
      isEdit: false,
      showDetail: false,
      isCopy: false,
      selected: [],
      isUpdate: false,
      status: 0,
      search: null,
      messagePlatformList,
      targetList: [],
    };
  },
  watch: {
    // accountChannelId() {
    //   this.getList();
    // },
    accountChannelId: {
      handler(newV) {
        this.getList();
        if (newV) {
          let platform = this.channelList.find(
            (item) => item.id === newV
          ).platform;
          this.targetList = this.channelList.filter(
            (item) => item.platform === platform
          );
        }
      },
      immediate: true,
    },
  },
  computed: {
    channelList() {
      const { officialList } = this;
      return officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    ...mapGetters(["officialList"]),
  },
  // beforeRouteEnter(to, form, next) {
  //   next((vm) => vm.getList());
  // },
  methods: {
    handleUpdateSubmit() {
      changeDelayStatus(this.selected, this.status).then(() => {
        this.$message.success("操作成功");
        this.getList();
        this.isUpdate = false;
      });
    },
    handleSelectionChange(rows) {
      this.selected = rows.map((item) => item.id);
    },
    handleRowClick(type, data) {
      if (type === "delete") {
        this.handleDelete([data.id]);
      } else if (type === "copy") {
        this.handleDialogShow(data, "isCopy");
      }
    },
    handleDelete(item) {
      this.$confirm("此操作将永久删除选中延迟回复消息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDelayMessage(item.join()).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        })
        .catch(() => {});
    },
    handleDialogShow(item, prop) {
      this[prop] = true;
      this.current = item;
    },
    getList() {
      if (this.accountChannelId) {
        this.loading = true;
        getDelayMessage(this.accountChannelId, this.search)
          .then((res) => {
            this.messageList = res;
          })
          .finally(() => (this.loading = false));
      }
    },
    formatOffset(sendOffset) {
      return sendOffset
        ? `${Math.floor(sendOffset / 3600)} 时 ${Math.floor(
            (sendOffset % 3600) / 60
          )} 分 ${Math.floor((sendOffset % 3600) % 60)} 秒`
        : 0;
    },
  },
  components: {
    Edit,
    Detail,
    Copy,
  },
};
</script>

<style lang="scss" scoped>
.operation {
  background: #fff;
  // padding: 0 15px;
}
.part {
  box-sizing: border-box;
  background: #fff;
}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
