<template>
<div class='message-delay-copy'>
    <el-form label-width='100px'>
      <el-form-item label="公众号">
        <el-select v-model="accountChannelIds" style="width:80%" placeholder="请选择" multiple clearable filterable>
          <el-option
              v-for="item in accountList"
              :key="item.id"
              :value="item.id"
              :label="item.channelName"
          >
           <span style="float: left">{{ item.channelName }}</span>
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span  class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button @click="handleSubmit" type="primary" :loading="submitLoading">确 定</el-button>
    </span>
</div>
</template>

<script>
import { copyDelayMessage } from '../../../api/service'

export default {
  name: 'CopyMesg',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: Array,
    accountList: Array
  },
  data () {
    return {
      accountChannelIds: [],
      submitLoading: false
    }
  },
  methods: {
    handleSubmit () {
      const { accountChannelIds } = this
      if (accountChannelIds && accountChannelIds.length) {
        this.submitLoading = true
        copyDelayMessage(this.info, accountChannelIds).then(() => {
          this.$message.success('复制成功')
          this.$emit('refresh')
          this.handleClose()
        }).finally(() => {
          this.submitLoading = false
        })
      } else {
        this.$message.error('请选择公众号')
      }
    },
    handleClose () {
      this.accountChannelIds = []
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.dialog-footer {
  display: inline-block;
  width:100%;
  margin-top: 40px;
  text-align: center;
}
</style>
